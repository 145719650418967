





























































































































































import { Component, Vue } from 'vue-property-decorator'
import { VeeValidateObserverRef } from '@/models/Common/Validation'
import UserService from '@/services/UserService'
import ModalSuccess from '@/components/Modal/ModalSuccess.vue'
import ModalError from '@/components/Modal/ModalError.vue'

@Component({
  components: {
    ModalSuccess,
    ModalError
  }
})
export default class ChangePassword extends Vue {
  $refs!: {
    observer: VeeValidateObserverRef
  }
  private errorConfirmPassword: boolean = false
  private showErrorPassword: boolean = false
  private showNewPassword: boolean = false
  private showCurrentPassword: boolean = false
  private currentPassword: string = ''
  private newPassword: string = ''
  private confirmNewPassword: string = ''
  private passwordStatusMsg: any = ''
  private confirmChange: any
  private confirmLeaveIgnore: boolean = false
  private isLoading: boolean = false

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (
      !this.confirmLeaveIgnore &&
      (this.currentPassword || this.newPassword || this.confirmNewPassword)
    ) {
      this.confirmChange = next
      this.$bvModal.show('modal-confirm')
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  togglePassword(type: string) {
    if (type === 'current_password') {
      this.showCurrentPassword = !this.showCurrentPassword
    }
    if (type === 'new_password') {
      this.showNewPassword = !this.showNewPassword
    }
  }

  changePassword() {
    this.$refs.observer.validate().then(async isValidate => {
      if (isValidate) {
        if (this.newPassword !== this.confirmNewPassword) {
          this.errorConfirmPassword = true
          return
        } else {
          let data = {
            current_password: this.currentPassword,
            new_password: this.newPassword,
            new_password_confirmation: this.confirmNewPassword
          }
          this.isLoading=true;
          await UserService.changePassword(data)
            .then(res => {
              if (res.status === 200) {
                this.$bvModal.show('modal-success')
                this.confirmLeaveIgnore = true
                this.errorConfirmPassword = false
                this.showErrorPassword = false
                this.currentPassword = ''
                this.newPassword = ''
                this.confirmNewPassword = ''
                this.$refs.observer.reset()
                this.isLoading=false
              }
            })
            .catch(error => {
              this.isLoading=false
              if (error.response.status === 422) {
                this.passwordStatusMsg = error.response.data.message[0]
                this.showErrorPassword = true
              } else {
                this.$bvModal.show('modal-error')
                this.showErrorPassword = false
                this.errorConfirmPassword = false
              }
            })
        }
      } else {
        return
      }
    })
  }

  resetMsg() {
    this.errorConfirmPassword = false
    this.showErrorPassword = false
  }
}
